
import Vue from 'vue';
import statusCheck from '../../mixins/statusCheck';
import { C360Provider, defineAbilityFor } from '@c360/ui';
import utils from '../../util';

export default Vue.extend({
  data: () => ({
    refreshToken: '',
    config: {
      whatIsNewDialog: {
        page: '',
      },
    },
  }),
  components: {
    AdminToolbar: () => import('../global/adminToolbar.vue'),
    AppGlobals: () => import('../global/globals.vue'),
    Status: () => import('../global/status.vue'),
    C360Provider,
  },
  mixins: [statusCheck],
  computed: {
    accessToken() {
      return localStorage.getItem('ah:accessToken');
    },
    refreshTokenLS() {
      return localStorage.getItem('ah:refreshToken');
    },
    ability() {
      return defineAbilityFor({
        isSuperUser: this.$store.state?.customer?.user?.is_superuser ?? false,
        isAgencyAdmin: utils.hasRight(this.$store.state?.customer?.user, ['AGENCY_ADMIN']) ?? false,
        products: this.$store.state?.customer?.user?.products ?? [],
        permissions: this.$store.state?.customer?.user?.permissions ?? [],
        activeAgencyName: this.$store.state?.customer?.user?.active_agency_id ?? '',
      });
    },
    c360Path(): string {
      return `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`;
    },
  },
  created(): void {
    if (this.$route.name === 'reportsadmin') {
      this.$router.push({ name: 'scheduledreports' });
    }
  },
  mounted() {
    this.refreshToken = this.$store.state?.customer?.user?.refreshToken;
    setTimeout(() => {
      this.config = {
        whatIsNewDialog: {
          page: `${window.location.protocol}//${window.location.host}${this.$route.fullPath}`,
        },
      };
    }, 500);
  },
  watch: {
    '$store.state.customer.user.accessToken': {
      handler(newState: boolean): void {
        this.refreshToken = newState;
      },
    },
  },
});
